import classNames from 'classnames';
import styles from './Modal.module.scss';

function Modal({
  active,
  setActive,
  children,
}) {
  const handleClick = () => setActive(false);

  const MC = classNames(styles.Modal, {
    [styles.Modal__isActive]: active,
  });

  return (
    <div
      className={MC}
      onClick={handleClick}
      role="button"
    >
      <div
        className={styles.Modal_Content}
        onClick={(e) => e.stopPropagation()}
        role="button"
      >
        <div className={styles.Modal_children}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default Modal;
