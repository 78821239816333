/* eslint-disable no-underscore-dangle */
import { useState } from 'react';
import Button from '../button/Button';
import styles from './SectionMid.module.scss';
import Modal from '../modal/Modal';

function SectionMid({ subservices, name, coment }) {
  const [selectedSubservices, setSelectedSubservices] = useState(new Set());

  // eslint-disable-next-line no-unused-vars
  const handleSelectSubservice = (subservice) => {
    const handler = () => {
      const newSet = new Set(selectedSubservices);

      if (newSet.has(subservice)) {
        newSet.delete(subservice);
      } else {
        newSet.add(subservice);
      }

      setSelectedSubservices(newSet);
    };

    return handler;
  };

  const [modalActive, setModalActive] = useState(false);

  return (
    <div className={styles.Background}>
      <div className={styles.Card}>
        <span>
          {name}
          <div className={styles.Size_Coment}><span>{coment}</span></div>
        </span>
        <div className={styles.Card_Footer}>
          <Button type="link" name="Більше..." onClick={() => setModalActive(true)} />
        </div>

        <Modal active={modalActive} setActive={setModalActive}>
          <div className={styles.Modal_Line} key={name}>
            <span>
              {name}
            </span>
            <div>
              {subservices.map((subservice) => (
                <div className={styles.Green_Line} key={subservice._id}>
                  <div className={styles.Modal_Content}>
                    <div>{subservice.name}</div>
                    <div className={styles.Modal_Content_Price}>{subservice.price}</div>
                  </div>

                </div>
              ))}
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default SectionMid;
