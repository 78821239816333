/* eslint-disable jsx-a11y/iframe-has-title */
import { useState } from 'react';
import classNames from 'classnames';
import styles from './Bottom.module.scss';
import Button from '../button/Button';
import Modal from '../modal/Modal';
import MakeAppointment from '../makeAppointment/MakeAppointment';

function Bottom({
  phonesone,
  phonestwo,
  address,
  mail,
}) {
  const [modalActive, setModalActive] = useState(false);
  const currentYear = new Date().getFullYear();
  const [selectedContacts, setSelectedContacts] = useState(new Set());
  // eslint-disable-next-line no-unused-vars
  const handleSelectContact = (contact) => {
    const handler = () => {
      const newSet = new Set(selectedContacts);

      if (newSet.has(contact)) {
        newSet.delete(contact);
      } else {
        newSet.add(contact);
      }

      setSelectedContacts(newSet);
    };

    return handler;
  };

  const MapAndAddress = classNames(styles.MapAndAddressOfLeft, styles.ContactsGeneral);

  const Information = classNames(styles.InformationOfCenter, styles.ContactsGeneral);

  const Registry = classNames(styles.RegistryOfRight, styles.ContactsGeneral);
  return (
    <div className={styles.BackgroundBottom}>
      <div className={styles.InteriorBlockBottom}>
        <div className={MapAndAddress}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2573.6237681069147!2d24.045060411923192!3d49.830736080620206!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473add5b35b00dfb%3A0xdda8ec6665111475!2sNika%20I%20K!5e0!3m2!1sua!24v1678645005142!5m2!1sua!"
            width="300"
            height="200"
            allowfullscreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            frameBorder="none"
          />
        </div>
        <div className={Information}>
          <span>{address}</span>
          <a href="mailto:{mail}">{mail}</a>
          <a href="tel:{phonesone}">{phonesone}</a>
          <a href="tel:{phonestwo}">{phonestwo}</a>
        </div>
        <div className={Registry}>
          <Button type="large" name="Записатись" onClick={() => setModalActive(true)} />
        </div>
      </div>
      <div>
        <div className={styles.Right}>
          <p>Всі права захищені: </p>
          <p>© Ніка і К </p>
          <p>2004-</p>
          <div>{currentYear}</div>
        </div>
      </div>
      <Modal active={modalActive} setActive={setModalActive}>
        <MakeAppointment setModalActive={setModalActive} isModalActive={modalActive} />
      </Modal>
    </div>
  );
}

export default Bottom;
