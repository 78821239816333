import { useState } from 'react';
import classNames from 'classnames';
import Button from '../button/Button';
import Modal from '../modal/Modal';
import MakeAppointment from '../makeAppointment/MakeAppointment';
import styles from './Header.module.scss';

function Header() {
  const [modalActive, setModalActive] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const buttonsConfig = [
    {
      name: 'Послуги',
      href: '#services',
      type: 'link',
    },
    {
      name: 'Записатись',
      type: 'button',
      onClick: () => setModalActive(true),
    },
    {
      name: 'Контакти',
      href: '#contact',
      type: 'link',
    },
    {
      name: 'Про нас',
      href: '#about',
      type: 'link',
    },
  ];

  const handleHamburgerClick = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  const headerBlockTwoClassName = classNames(styles.HeaderBlockTwo, {
    [styles.HeaderBlockTwo__isActive]: isMenuVisible,
  });

  return (
    <header className={styles.Background}>

      <div className={styles.BigBlockHeader}>
        <div className={styles.HeaderBlockOne}>
          <a href="/">
            <img src="./image/logo.png" alt="logo" />
          </a>
        </div>

        <div className={styles.Hamburger} onClick={handleHamburgerClick} role="button">
          <span />
          <span />
          <span />
        </div>

        <div className={headerBlockTwoClassName}>
          {buttonsConfig.map(({ name, href, onClick }) => (
            <Button
              key={name}
              type="link"
              name={name}
              href={href}
              onClick={onClick}
            />
          ))}
        </div>
      </div>

      <Modal
        active={modalActive}
        setActive={setModalActive}
      >
        <MakeAppointment />
      </Modal>
    </header>
  );
}

export default Header;

// styles.Header
// styles.Header_Logo

// styles.Header__isSticky

// styles.Header_Logo__isActive
// styles.Header_Logo__isDisabled
