import { useState } from 'react';
import styles from './Information.module.scss';

function Information({ name }) {
  const [selectedTexts, setSelectedTexts] = useState(new Set());

  // eslint-disable-next-line no-unused-vars
  const handleSelectText = (text) => {
    const handler = () => {
      const newSet = new Set(selectedTexts);

      if (newSet.has(text)) {
        newSet.delete(text);
      } else {
        newSet.add(text);
      }

      setSelectedTexts(newSet);
    };

    return handler;
  };

  return (
    <div className={styles.Cover}>
      <div className={styles.Cover_Name}>
        Інформація про нас:
      </div>
      <div className={styles.Cover_Text}>
        {name}
      </div>
    </div>

  );
}
// background-color: #9b9b9b;
export default Information;
