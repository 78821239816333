import { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './MakeAppointment.module.scss';
import Button from '../button/Button';

function MakeAppointment({ setModalActive, isModalActive }) {
  const [nameInput, setNameInput] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [phoneInput, setPhoneInput] = useState('');
  const [messageInput, setMessageInput] = useState('');
  const [checkboxInput, setCheckboxInput] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (isModalActive) {
      setIsSubmitted(false);
    }
  }, [isModalActive]);

  function handleReset() {
    setNameInput('');
    setEmailInput('');
    setPhoneInput('');
    setMessageInput('');
    setCheckboxInput(false);
  }

  async function sendForm(event) {
    event.preventDefault();

    let message = '<b>Запис</b>\n';

    message += `<b>Ім'я: </b> ${nameInput}\n`;
    message += `<b>Пошта: </b> ${emailInput}\n`;
    message += `<b>Номер: </b> ${phoneInput}\n`;
    message += `<b>Інформація: </b> ${messageInput}`;

    try {
      await axios.post('http://127.0.0.1:4000/sendToTelegram', { message });
      setIsSubmitted(true);
      setTimeout(() => {
        setModalActive(false);
      }, 5000);
      handleReset();
    } catch (e) {
      setIsError(true);
      setTimeout(() => {
        setIsError(false);
      }, 5000);
    }
  }

  return (
    <form className={styles.Background} onSubmit={sendForm}>
      <div className={styles.InputBlock}>
        <div className={styles.NameOfInputBlock}>
          Запис на прийом до лікаря.
          <br />
          Ми вам зателефонуємо!
        </div>
        <div>
          <p>Ім’я:</p>
          <input type="text" value={nameInput} onChange={(e) => setNameInput(e.target.value)} />
        </div>
        <div>
          <p>Номер телефону:</p>
          <input type="tel" value={phoneInput} onChange={(e) => setPhoneInput(e.target.value)} required />
        </div>
        <div>
          <p>Електронна адреса:</p>
          <input type="email" value={emailInput} onChange={(e) => setEmailInput(e.target.value)} required />
        </div>
        <div>
          <p>Опис(необов’язково):</p>
          <input type="text" value={messageInput} onChange={(e) => setMessageInput(e.target.value)} />
        </div>
        <div>
          <label htmlFor="checkbox1">
            <input id="checkbox1" type="checkbox" checked={checkboxInput} onChange={(e) => setCheckboxInput(e.target.checked)} required />
            Я погоджуюсь з обробкою персональних даних.
          </label>
        </div>
      </div>

      <div className={styles.SubmitBlock}>
        <Button type="green" htmlType="submit" name="Надіслати" />
        {isSubmitted && (
          <div className={styles.SuccessMessage}>
            <p>Дякуємо! Ми зв’яжемось з вами найближчим часом.</p>
          </div>
        )}
        {isError && (
          <div className={styles.ErrorMessage}>
            <p>Нажаль виникла помилка, спробуйте, будь ласка, пізніше.</p>
          </div>
        )}
      </div>
    </form>
  );
}

export default MakeAppointment;
