import { useState } from 'react';
import styles from './SectionTop.module.scss';
import Button from '../button/Button';
import Modal from '../modal/Modal';
import MakeAppointment from '../makeAppointment/MakeAppointment';

function SectionTop() {
  const [modalActive, setModalActive] = useState(false);

  return (
    <div className={styles.BackgroundTop}>
      <div className={styles.InteriorBlockTop}>
        <div className={styles.InteriorBlockLeftTop}>
          <div className={styles.InteriorBlockLeftTextBottomOne}>
            Самолікування може бути шкідливим для вашого здоров&apos;я,
            лікуйся та займайся профілактикою разом з нами!
          </div>

          <div className={styles.InteriorBlockLeftTextBottomTwo}>
            Запишись, ми зателефонуємо!
          </div>

          <Button type="large" name="Записатись" onClick={() => setModalActive(true)} />
        </div>

        <Modal active={modalActive} setActive={setModalActive}>
          <MakeAppointment setModalActive={setModalActive} isModalActive={modalActive} />
        </Modal>

        <div className={styles.InteriorBlockRightTop}>
          <img src="./image/girls.jpg" alt="pregnant girl" />
        </div>
      </div>
    </div>
  );
}

export default SectionTop;
