/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import axios from 'axios';
import Header from './components/header/Header';
import SectionTop from './components/sectionTop/SectionTop';
import SectionMid from './components/sectionMid/SectionMid';
import Bottom from './components/bottom/Bottom';
import Information from './components/information/Information';

function App() {
  const [services, setServices] = useState([]);
  const [text, setText] = useState({});
  const [contact, setContact] = useState({});

  const getServices = async () => {
    const response = await axios.get('http://127.0.0.1:4000/services');
    if (response?.status === 200 && response?.data?.length) {
      setServices(response.data);
    }
  };

  const getText = async () => {
    const response = await axios.get('http://127.0.0.1:4000/texts');
    if (response?.status === 200 && response?.data) {
      setText(response.data);
    }
  };

  const getContact = async () => {
    const response = await axios.get('http://127.0.0.1:4000/contacts');
    if (response?.status === 200 && response?.data) {
      setContact(response.data);
    }
  };

  useEffect(() => {
    getServices();
    getText();
    getContact();
  }, []);

  return (
    <div className="App">

      <section>
        <Header />
      </section>

      <section>
        <SectionTop />
      </section>

      <div className="BackTableBlock">
        <div className="BackTableBlockText">Як ми можемо допомогти:</div>
        <div className="TableBlock">
          {services.map((service) => (
            // eslint-disable-next-line no-underscore-dangle
            <section id="services" key={service._id}>
              <SectionMid
                name={service.name}
                subservices={service.subservices}
                coment={service.coment}
              />
            </section>
          ))}
        </div>
      </div>

      <section id="about">
        <Information
          name={text.name}
        />
      </section>

      <footer id="contact">
        <Bottom
          phonesone={contact.phonesone}
          phonestwo={contact.phonestwo}
          address={contact.address}
          mail={contact.mail}
        />
      </footer>
    </div>
  );
}

export default App;
